import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import { Toast, Dialog, Checkbox } from 'vant'
import { Popover } from 'vant'
import { Popup } from 'vant'
import { Loading } from 'vant'

Vue.use(Loading)
Vue.use(Popup)
Vue.use(Popover)
Vue.use(Checkbox)
Vue.use(Toast)
Vue.use(Dialog)
export default {
  name: 'infoSure',
  components: {},
  data () {
    return {
      false1: false,
      show: false,
      checked: false,
      showPopover: false,
      showPopover1: false,
      infoList: {},
      infoImgs: {
        bxClaimsCertificateImgs: [],
        bxClaimsCaseHistoryImgs: [],
        bxClaimsBankImgs: [],
        bxClaimsCostProveImgs: [],
        bxClaimsPathologicalDiagnosisReportImgs: [],//病理诊断报告
        bxClaimsPrescriptionDrugImgs: [],//药品处方
        bxClaimsGeneticTestingReportImgs: [],//免疫组化/基因检测报告
      }


    }
  },
  beforeCreate () {

  },
  created () {
    this.infoList = this.$store.state.DetailList
    this.infoImgs = this.$store.state.uploadFiles

  },
  methods: {
    ...mapActions('claims', ['subApplyClaims']),
    goxie () {
      this.$router.push({ name: 'antiFraud' })

    },
    changeOne () {
      this.showPopover = true
    },
    changeOne1 () {
      this.showPopover1 = true
    },
    back () {
      this.$router.go(-1)
    },
    tosub () {
      if (this.checked) {
        this.temporaryStorage()

      } else {
        Toast('请勾选《反欺诈协议》')
      }
    },
    temporaryStorage () {
      this.show = true
      var claimsList = this.$store.state.DetailList
      claimsList.bxClaimsCertificateImgs = this.infoImgs.bxClaimsCertificateImgs.join(',')
      claimsList.bxClaimsCaseHistoryImgs = this.infoImgs.bxClaimsCaseHistoryImgs.join(',')
      claimsList.bxClaimsBankImgs = this.infoImgs.bxClaimsBankImgs.join(',')
      claimsList.bxClaimsCostProveImgs = this.infoImgs.bxClaimsCostProveImgs.join(',')
      //  新增的三个
      claimsList.bxClaimsPathologicalDiagnosisReportImgs = this.infoImgs.bxClaimsPathologicalDiagnosisReportImgs.join(',')
      claimsList.bxClaimsPrescriptionDrugImgs = this.infoImgs.bxClaimsPrescriptionDrugImgs.join(',')
      claimsList.bxClaimsGeneticTestingReportImgs = this.infoImgs.bxClaimsGeneticTestingReportImgs.join(',')

      /////
      // if(claimsList.bxOrderInsurantCerType==1){
      //  claimsList.bxOrderInsurantCerType='01'
      // }else if(claimsList.bxOrderInsurantCerType==3){
      //  claimsList.bxOrderInsurantCerType='03'
      // }else if(claimsList.bxOrderInsurantCerType==5){
      //  claimsList.bxOrderInsurantCerType='41'
      // }else if(claimsList.bxOrderInsurantCerType==6){
      //  claimsList.bxOrderInsurantCerType='42'
      // }

      if (claimsList.bxClaimsState == 2) {
        claimsList.beforeState = claimsList.bxClaimsState
      }
      claimsList.bxClaimsState = 1
      this.subApplyClaims(claimsList).then(res => {
        this.show = false
        if (res.code == 200) {
          this.changeData()
          this.$router.push({ name: 'prompt' })

        } else {
          Toast(res.msg)
        }

      })
    }









  },
}
